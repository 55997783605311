import React, { useEffect } from "react";
import { inject } from "mobx-react";
import { Routes, Route } from 'react-router-dom';
import { ToastContainer} from 'react-toastify';
import { Container } from "react-bootstrap";
import { v4 as uuid } from 'uuid';

//components
import withLocalization from "../hoc/withLocalization";
import CustomerNavbar from "../components/Navbars/CustomerNavbar";

//routes
import customerRoutes from "../customerRoutes";

const CustomerLayout = (props) => {
    const handleLogOut = () => {
        window.sessionStorage.removeItem('cusToken');
    }

    const isSticky = () => {
        const screenSize = window.screen;
        if (screenSize && screenSize.width && screenSize.width > 992) {
            const header = document.querySelector('.customerMain');
            const scrollTop = window.scrollY;
            scrollTop >= 150 && header ? header?.classList.add('is-sticky') : header?.classList.remove('is-sticky');
        }
    }
    
    useEffect(() => {
        if (window.sessionStorage.getItem('cusToken')) {
            props.authStore.loadCustConfig().then((res) => {
                console.log({ res })
            })
        }
        window.addEventListener('scroll', isSticky);
    }, [])

    const getRoutes = (routes) => {
        return routes.map((prop) => {
            if (prop.allowed === undefined || prop.allowed()) {
                if (prop.collapse) {
                    return getRoutes(prop.views);
                }
                if (prop.hasChildRoutes) {
                    const parent = { ...prop };
                    if (parent.hasChildRoutes) delete parent.hasChildRoutes;
                    if (parent.childRoutes) delete parent.childRoutes;
                    const combinedRoutes = [parent, ...prop.childRoutes];
                    return getRoutes(combinedRoutes);
                }
                if (prop.component !== undefined) {
                    return <Route path={prop.path} key={uuid()} element={<prop.component />} />;
                }
                return null;
            } else {
                return null;
            }
        });
    };

    return (
        <>
            <ToastContainer />
            <Container>
                <div className={`main-panel ${'w-100'}`} id="main-panel" >
                    <CustomerNavbar
                        handleLogOut={handleLogOut}
                    />
                    <div className="main-content">
                        <Routes>{getRoutes(customerRoutes)}</Routes>
                    </div>
                    <div className="customer-layout-footer">
                        <div className="text-center fw-600">
                            {props.t('Velkommen til Time & Control – intelligent programvare for HR og administrasjon av ansatte')}.
                        </div>
                        <div className="text-center fs-6">
                            © 2024 Time & Control. All Rights Reserved.
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default inject('authStore')(withLocalization(CustomerLayout));