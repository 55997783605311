import moment from 'moment/min/moment-with-locales';
import { serverIsAmPmTimeFormat } from '../library/core';

export const getMinutesFromTimeInput = (inputValue) => {
    if (!inputValue || !inputValue.split) return 0;
    const inputArray = inputValue.split(':');
    let [hours, minutes] = inputArray;
    hours = +hours;
    minutes = +minutes;
    return hours * 60 + minutes;
};

export const minutesToTimeInput = (allMinutes, allowUseAmPmFormat) => {
    const hours = Math.floor(+allMinutes / 60);
    const minutes = Math.floor(+allMinutes % 60);
    if (allowUseAmPmFormat === 'full') {
        return {
            positive: hours > 0 || minutes > 0,
            value: `${hours < 10 && hours >= 0 ? `0${hours}` : hours}:${minutes < 10 && minutes >= 0 ? `0${minutes}` : minutes
                }`,
        };
    }
    if (!allowUseAmPmFormat || !serverIsAmPmTimeFormat() || hours >= 24) {
        return hours > 0 || minutes > 0
            ? `${hours < 10 && hours >= 0 ? `0${hours}` : hours}:${minutes < 10 && minutes >= 0 ? `0${minutes}` : minutes
            }`
            : '00:00';
    }
    // am-pm format
    let formatHours = hours;
    let suffix = 'am';
    if (hours === 0 && minutes === 0) return '00:00';
    if (hours > 12) suffix = 'pm';
    formatHours = `${hours}`;
    if (hours >= 13) {
        formatHours = `${hours - 12}`;
    } else if (hours === 0) {
        formatHours = 12;
    }
    return `${formatHours}:${minutes < 10 && minutes >= 0 ? `0${minutes}` : minutes} ${suffix}`;
};

export const minutesToTimeInputV2 = (allMinutes, format) => {
    const hours = Math.floor(+allMinutes / 60);
    const minutes = Math.floor(+allMinutes % 60);
    if (format === 'hh:mm') {
        return hours > 0 || minutes > 0
            ? `${hours < 10 && hours >= 0 ? `0${hours}` : hours}:${minutes < 10 && minutes >= 0 ? `0${minutes}` : minutes
            }`
            : '00:00';
    }

    let formatHours = hours;
    let suffix = 'am';
    if (hours === 0 && minutes === 0) return '12:00 am';
    if (hours >= 12) suffix = 'pm';
    formatHours = `${hours}`;
    if (hours === 0) {
        formatHours = '12';
    }
    else if (hours >= 13) {
        formatHours = `${hours - 12}`;
    } else if (hours === 12) {
        formatHours = 12;
    }

    return `${formatHours < 10 && formatHours >= 0 ? `0${formatHours}` : `${formatHours}`}:${minutes < 10 && minutes >= 0 ? `0${minutes}` : minutes} ${suffix}`;
};

export const getMinutes = (timeZoneDiff) => {
    const now = new Date();
    const hours = now.getUTCHours();
    const minutes = now.getUTCMinutes();
    const totalMinutes = hours * 60 + minutes + (timeZoneDiff * 60);
    return totalMinutes;
};

export const timeInputToMoment = (input) => {
    if (!input) return null;
    const inputArray = input.split(':');
    const [hours, minutes] = inputArray;
    const d = moment(new Date())
        .hour(+hours)
        .minute(+minutes);
    return d;
};

export const momentToTimeInput = (m) => {
    if (!m) return null;
    return m.format('HH:mm');
};

export const compareDates = (a, b = new Date(), unitOfTime = 'day') => {
    return moment(a).diff(moment(b), unitOfTime, true);
}