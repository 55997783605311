import React, { Component } from 'react'
import { inject } from 'mobx-react';

//styles//
import "./style.css"
import '../../../styles/css/styles.css'

//assests //
import addBtn from '../../../assets/img/plus.png'
import removeBtn from '../../../assets/img/minus.png'

//elements//
import Select from '../../../elements/Select'
import LoadingSpinner from '../../../elements/LoadingSpinner';
import { Input } from '../../../elements/Input'

//components//
import withLocalization from '../../../hoc/withLocalization';
import applicationRouter from '~/hoc/applicationRouter'
import { ProjectsWidget } from '../../../components/GenericForm/Widgets/ProjectsWidget';
import Button from '../../../components/CustomButton/CustomButton';

class MonitoringForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            project_id: '',
            unit: {value: '', label: ''},
            product: [{ productName: "" }],
            item: [{ itemName: "" }],
            type: '',
            fetching: true
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleProductChange(i, e) {
        let product = this.state.product;
        product[i][e.target.name] = e.target.value;
        this.setState({ product });
    }

    handleItemChange(i, e) {
        let item = this.state.item;
        item[i][e.target.name] = e.target.value;
        this.setState({ item });
    }

    addFormFields(i) {
        this.setState(({
            product: [...this.state.product, { productName: "" }]
        }))
    }
    addItemFormFields(i) {
        this.setState(({
            item: [...this.state.item,{ itemName: "" }]
        }))
    }
    removeItemFormFields(i) {
        const { monitorStore } = this.props;
     
        // if (this.props.id){
        //     monitorStore.setDeletedItemIndex(i+1);
        // }

        if(this.props.router.location.state?.monitoring){
            monitorStore.setDeletedItemIndex(i+1);
        }
        let item = this.state.item;
        item.splice(i, 1);
        this.setState({ item });
    }
    removeFormFields(i) {
        const { monitorStore } = this.props;
        // if (this.props.id){

        //     monitorStore.setDeletedProductIndex(i+1);
        // }
        if(this.props.router.location.state?.monitoring){
            monitorStore.setDeletedProductIndex(i+1);
        }
        let product = this.state.product;
        product.splice(i, 1);
        this.setState({ product });
    }
    
    handleChange(name, data) {
        this.setState({ [name]: data });
    };
    handleUnitChange(name, data) {
        this.setState({ [name]: data });
    };
    handleInputChange = (event) => {
        this.setState({ name: event.target.value });
    }

    handleClick = (type) => {
        if (type === 'save') {
            this.validate();
            this.onSave();
        }
        if(type === 'cancel'){
            this.onCancel('cancel');
        }
    }
    
    componentDidMount() {
        const { monitorStore } = this.props; 
        let getId = (this.props.router.location.state && this.props.router.location.state.monitoring) || null;
        if (getId) {
            monitorStore.load(getId).then((res) => {
                if (res) {
                    const products = res.monitor.metadata.products.map((data) => {
                        return { productName: data }
                    })
                    const items = res.monitor.metadata.items.map((data) => {
                        return { itemName: data }
                    })
                    products.shift(0);
                    items.shift(0);
                    let label;
                    if (res.monitor.unit === "°C") {
                        label = 'Celsius'
                    } else if (res.monitor.unit === "°F") {
                        label = 'Fahrenheit'
                    }
                    else if (res.monitor.unit === " ") {
                        label = 'Null'
                    }

                    const unit = { value: res.monitor.unit, label }
                    this.setState({ name: res.monitor.name, project_id: res.monitor.project_id, unit: unit, product: products, item: items, fetching: false });
                }
            })
        }
        
    }
    validate = () => {
        if (!this.state.name || !this.state.project_id || !this.state.unit) {
            return true;
        }
        for (let i = 0; i < this.state.product.length; i++) {
            if (this.state.product[i] !== '') {
                if (this.state.product[i].productName === '') {
                    return true;
                }
            }
            for (let j = i + 1; j < this.state.product.length; j++) {
                if (this.state.product[i].productName === this.state.product[j].productName) {
                    return '2';
                }
            }
        }
        for (let i = 0; i < this.state.item.length; i++) {
            if (this.state.item[i] !== undefined) {
                if (this.state.item[i].itemName === '') {
                    return true;
                }
            }
            for (let j = i + 1; j < this.state.item.length; j++) {
                if (this.state.item[i].itemName === this.state.item[j].itemName) {

                    return '2';
                }
            }
        }
        return false
    }
    onSave() {
        const { monitorStore, t, commonStore } = this.props;
        const state = Object.assign({}, this.state);
        const isEmpty = this.validate()
        if (isEmpty === true) {
            commonStore.addNotification(t('Please Add All Required Field'), null, 'error');
            return;
        } else if (isEmpty === '2') {
            commonStore.addNotification(t('Product name can\'t be same'), null, 'error');
            return
        }
        const products = this.state.product.map((products) => products.productName)
        products.unshift("#");
        const items = this.state.item.map((items) => items.itemName)
        items.unshift("clock");
        state.unit = state.unit.value
        state.metadata = { products, items }
        let getId = (this.props.router.location.state && this.props.router.location.state.monitoring) || null;
        if (getId) {
            monitorStore.edit(getId, state).then((result) => {
                commonStore.addNotification(t('Saved'), null, 'success');
                monitorStore.loadDataSync(result);
                this.props.router.navigate("/admin/monitor/addGrid",{state:{monitoring:result.id}});
            });

        } else {
            monitorStore.save(state).then(result => {
                if (!result || !result.id) {
                    commonStore.addNotification(t(result.message || 'Error'), null, 'error');
                    return true;
                }
                commonStore.addNotification(t('Saved'), null, 'success');
                monitorStore.loadDataSync(result);
                this.props.router.navigate("/admin/monitor/addGrid",{state:{monitoring:result.id}});
            });
        }
    }
    onCancel() {
        this.props.router.navigate("/admin/monitor");
    }
    render() {
        if (this.props.monitorStore.loading){
            return <LoadingSpinner />
        }
        const { t } = this.props;
        const unitDropDown = [
            { value: '°F', label: 'Fahrenheit' },
            { value: '°C', label: 'Celsius' },
            { value: 'Km', label: 'Kilometer' },
            { value: ' ', label: 'None' },
        ]
        // handle input change
        return (
            <>
                <div className='monitor-form-data'>
                    <div className='monitor-title'>
                        <h5>{t('ADD NEW MONITORING')}</h5>
                    </div>

                    <div className="monitor-form-row1 col-md-12">
                        <div className="col-md-4 add-monitor">
                            <label htmlFor='name'>{t('Name')}</label><span style={{ color: 'red' }}>*</span>
                            <Input
                                name="name"
                                placeholder={t("Temperature Monitoring")}
                                value={this.state.name}
                                onChange={this.handleInputChange}
                            />
                        </div>
                        <div className="col-md-4 project-list">
                            <label htmlFor="project_name">{t('Project')}</label><span style={{ color: 'red' }}>*</span>
                            <ProjectsWidget
                                value={this.state.project_id}
                                disabled={false}
                                name="project_name"
                                onChange={(id) => { this.handleChange('project_id', id) }}
                            />
                        </div>
                        <div className="col-md-4 unit-list">
                            <label htmlFor="unit">{t('Unit')}</label><span style={{ color: 'red' }}>*</span>
                            <Select
                                options={unitDropDown}
                                value={this.state.unit}
                                placeholder={t("Select Unit")}
                                name="unit"
                                onChange={(evt) => { this.handleUnitChange('unit', evt) }}
                            />
                        </div>
                    </div>
                    
                    <div className="monitor-form-row2 col-md-12 ">
                        <div className="product-textfields col-md-5">
                            <div className='product-field'>
                                <label htmlFor="productInput">{t('Products')}</label><span style={{ color: 'red' }}>*</span>
                                {this.state.product.map((element, index) => (
                                    <div className='d-flex' key={index}>
                                        <div>
                                            <Input
                                                type="text"
                                                value={element.productName || ""}
                                                name="productName"
                                                placeholder={t("Enter Product Name")}
                                                onChange={e => this.handleProductChange(index, e)}
                                            />
                                        </div>
                                        {
                                            ( index ===  this.state.product.length - 1 && this.state.product.length !== 1 ) && <img src={removeBtn} alt='removebtn' onClick={() => this.removeFormFields(index)}></img>
                                        }
                                        {
                                           ( index ===  this.state.product.length - 1 && this.state.product.length <= 9 ) && <img src={addBtn} alt='addbtn' onClick={() => this.addFormFields(index)}></img>
                                        }
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="item-textfields col-md-4">
                            <div className='item-field'>
                                <label htmlFor="itemInput">{t('Items')}</label><span style={{ color: 'red' }}>*</span>
                                {this.state.item.map((element, index) => (
                                    <div className='d-flex' key={index}>
                                        <div >
                                            <Input
                                                type="text"
                                                value={element.itemName || ""}
                                                name="itemName"
                                                placeholder={t("Enter Item Name")}
                                                onChange={e => this.handleItemChange(index, e)}
                                            />
                                        </div>
                                        {  
                                            ( index ===  this.state.item.length - 1 && this.state.item.length !== 1 ) &&<img src={removeBtn} alt='removeBtn' onClick={() => this.removeItemFormFields(index)}></img> 
                                        }
                                        { ( index ===  this.state.item.length - 1 && this.state.item.length <= 9) && <img src={addBtn} alt='addbtn' onClick={() => this.addItemFormFields(index)}></img> }
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='btn-monitor'>
                    <Button fill wd onClick={values => this.handleClick('save', values)}>
                        {t('Save')}
                    </Button>
                    <Button wd onClick={() => this.props.router.navigate("/admin/monitor")}>
                        {t('Cancel')}
                    </Button>
                </div>
            </>
        )
    }
}

export default inject('authStore', 'userStore', 'commonStore', 'monitorStore')(withLocalization(applicationRouter(MonitoringForm)));