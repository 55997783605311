import React, { Component } from 'react';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import moment from 'moment';
import { inject } from 'mobx-react';

//components
import withLocalization from '~/hoc/withLocalization';
import { longDateFormat, shortDateFormat } from '../../../utils/dateFormat.js';
import applicationRouter from '../../../hoc/applicationRouter.js';
import { ProjectsWidget } from '../../../components/GenericForm/Widgets/ProjectsWidget.js';
import { TasksWidget } from '../../../components/GenericForm/Widgets/TasksWidget.js';

//utils
import { getMinutesFromTimeInput, minutesToTimeInput } from '../../../utils/timeCalc.js';

//element
import Input, { NumberInput, Textarea } from '../../../elements/Input.js';
import DatePicker from '../../../components/DatePicker/DatePicker.jsx';
import TimePickerInput from '../../../components/TimePicker/index.js';
import Select from '../../../elements/Select.js';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import CustomMultiSelect from '../../../elements/CustomMultiSelect.js';
import RequiredStar from '../../../elements/RequiredStar.js';


class ServiceRequestsForm extends Component {
    constructor(props) {
        super(props);
        const start_time = this.props.authStore?.custConfig?.clientData?.startTimeRules?.start || this.props?.commonStore?.config?.client?.data?.basicRules?.startTimeRules?.start;
        const end_time = this.props.authStore?.custConfig?.clientData?.startTimeRules?.end || this.props.commonStore.config.client.data.basicRules.startTimeRules.end
        this.state = {
            userOptions: [],
            selectedMembers: [],
            projectOptions: this.props.authStore.custConfig?.projects?.map(ele => ({ value: ele.id, label: ele.name })),
            taskOptions: this.props.authStore.custConfig?.tasks?.map(ele => ({ value: ele.id, label: ele.name, project_id: ele.project_id })),
            project_id: null,
            task_id: null,
            no_of_member: 1,
            from_date: moment(new Date()).add(1, 'day'),
            to_date: moment(new Date()).add(1, 'day'),
            from_time: getMinutesFromTimeInput(start_time),
            to_time: getMinutesFromTimeInput(end_time),
            comment: '',
            status: '',
            memberCount: 0
        }
    }

    componentDidMount() {
        this.loadData();
    }

    validateFeilds() {
        const { project_id, task_id, from_time, to_time, to_date, from_date } = this.state;
        if (!project_id)
            return 'Please Select Project';
        if (!task_id)
            return 'Please select Service';
        if (!from_time)
            return 'Please select from time';
        if (!to_time)
            return "Please select to time";
        if (moment(to_date).isBefore(from_date))
            return "From date should be before to date";
    }

    async loadData() {
        const { userStore, serviceRequestStore, authStore } = this.props;
        const { currentUser } = userStore;
        let getId = (this.props.router.location.state && this.props.router.location.state.customer) || this.props.update_id || null;
        if (getId) {
            const getData = await serviceRequestStore.load(getId, currentUser?.user_type);
            const { availableMembers, serviceRequest } = getData;
            this.setState({
                ...getData?.serviceRequest,
                project_id: authStore?.custConfig?.projects?.map(ele => ele.id === serviceRequest.project_id && { id: ele.id, label: ele.name }) || serviceRequest.project_id,
                task_id: authStore?.custConfig?.tasks?.map(ele => ele.id === serviceRequest.task_id && { id: ele.id, label: ele.name }) || serviceRequest.task_id,
                userOptions: availableMembers,
                status: serviceRequest?.status,
                selectedMembers: serviceRequest?.status === 'approved' && currentUser?.user_type && availableMembers.map(ele => ele.value)
            });
        }
    }

    handleChange = (name, value) => {
        const { authStore } = this.props;
        if (name === 'no_of_member' && value < 1) return
        if (name === 'project_id') this.setState({ task_id: '' })
        if (name === 'task_id') {
            this.setState({ memberCount: authStore?.custConfig?.availableMembersPerTask?.find(ele => ele.task_id === value?.value)?.member_count })
        }
        this.setState({ [name]: value })
    }

    handleSave = async () => {
        const { serviceRequestStore, t, commonStore, router } = this.props;
        try {
            const getError = this.validateFeilds()
            if (getError) return commonStore.addNotification(t(getError), null, 'error');
            let getId = (router.location.state && router.location.state.customer) || this.props.update_id || null;
            const savedServiceRequest = await serviceRequestStore.save({
                ...this.state,
                project_id: this.state.project_id.value,
                task_id: this.state.task_id.value,
                id: getId
            }, !getId, window.sessionStorage.getItem('cusToken'));
            if (savedServiceRequest.warning)
                commonStore.addNotification(t(savedServiceRequest.warning), null, 'warning');
            else
                commonStore.addNotification(t(savedServiceRequest.message), null, 'success');
            if (window.sessionStorage.getItem('cusToken'))
                router.navigate('/customer');
            else
                router.navigate('/admin/serviceRequests');
        }
        catch (err) {
            commonStore.addNotification(t(err.message), null, 'error');
        }
    }

    handleCancel = async () => {
        const { router } = this.props;
        if (window.sessionStorage.getItem('cusToken'))
            router.navigate('/customer');
        else
            router.navigate('/admin/serviceRequests');
    }

    handleStatus = async (action) => {
        const { serviceRequestStore, t, commonStore } = this.props;
        try {
            const { selectedMembers, userOptions } = this.state;
            if (!selectedMembers?.length && action === 'approved') return commonStore.addNotification(t('To approve and assign shifts, please ensure that you select the appropriate team members'), null, 'error');
            const savedServiceRequest = await serviceRequestStore.saveStatus({
                selectedMembers: userOptions.filter(ele => selectedMembers.includes(ele.value)),
                id: serviceRequestStore.currentEntity?.serviceRequest?.id,
                status: action
            });
            commonStore.addNotification(t(savedServiceRequest.message), null, 'success');
            serviceRequestStore.resetLastListLoadTime();
            this.props.handleCloseServiceRequestModal();
        }
        catch (err) {
            commonStore.addNotification(t(err), null, 'error');
        }
    }

    handleShiftAssignMembers = (selectedUser) => {
        const { commonStore, t } = this.props;
        if (selectedUser.length > this.state.no_of_member)
            return commonStore.addNotification(`${t('You can select upto')} ${this.state.no_of_member} ${t('Members')}`, null, 'error');
        this.setState({ selectedMembers: selectedUser })
    }

    render() {
        const { t, commonStore, disableInput, projectStore } = this.props;
        const { project_id, task_id, no_of_member, from_date, to_date, projectOptions, taskOptions, from_time, to_time, comment, userOptions, selectedMembers, status, memberCount } = this.state;
        const { config } = commonStore;
        const dateTimeRules = config.client && config.client.data && config.client.data.dateTimeRules ? config.client.data.dateTimeRules : false;
        const format = dateTimeRules && dateTimeRules.time_format ? dateTimeRules.time_format : 'hh:mm';
        let _taskOptions = taskOptions
        if (project_id)
            _taskOptions = taskOptions?.filter(ele => ele.project_id === project_id.value)
        return (
            !disableInput ?
                <>
                    <div className='d-flex justify-content-between'>
                        <div className='table-container-header'>{t('Service Requests Form')}</div>
                        <div>
                            <Button onClick={() => config?.client ? this.props.router.navigate('/admin/serviceRequests') : this.props.router.navigate('/customer')}>
                                {t('Go Back')}
                            </Button>
                        </div>
                    </div>
                    <div className='mt-2'>
                        <Row>
                            <Col md={6} xs={12}>
                                <label className='fw-600'>{t('Project')}<RequiredStar /></label>
                                <OverlayTrigger
                                    placement="right"
                                    overlay={
                                        <Tooltip id="tooltip-top">
                                            {t("Please select the project you would like the team member to work on")}
                                        </Tooltip>
                                    }
                                >
                                    <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                </OverlayTrigger>
                                {config?.client ?
                                    <>
                                        <ProjectsWidget
                                            label={`${t('Project')}`}
                                            allLabel={t('All projects')}
                                            value={project_id}
                                            onChange={(e) => this.handleChange('project_id', { value: e })}
                                        />
                                        {project_id &&
                                            <>
                                                <label className='fw-600'>{t('Email for which you are creating a request on behalf of')}</label>
                                                <Input
                                                    value={projectStore?.currentProjectsLookup.managers?.find(ele => Number(ele.id) === Number(project_id?.value))?.email || t('No Email Found for selected project')}
                                                    disabled
                                                />
                                            </>
                                        }
                                    </>
                                    :
                                    <Select
                                        options={projectOptions}
                                        value={project_id}
                                        onChange={(e) => this.handleChange('project_id', e)}
                                    />
                                }
                                <label className='fw-600'>{t('Service')}<RequiredStar /></label>
                                <OverlayTrigger
                                    placement="right"
                                    overlay={
                                        <Tooltip id="tooltip-top">
                                            {t("Select the service you wish the team member to work on")}
                                        </Tooltip>
                                    }
                                >
                                    <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                </OverlayTrigger>
                                {config?.client ?
                                    <TasksWidget
                                        className="mt-2 pb-2"
                                        value={task_id}
                                        onChange={(e) => this.handleChange('task_id', { value: e })}
                                    />
                                    :
                                    <Select
                                        options={_taskOptions}
                                        value={task_id}
                                        onChange={(e) => this.handleChange('task_id', e)}
                                    />
                                }
                                <label className={'mt-2 fw-600'}>{t('Total Members Required')}{memberCount ? `(${memberCount} ${t('Available members')})` : ''}</label>
                                <OverlayTrigger
                                    placement="right"
                                    overlay={
                                        <Tooltip id="tooltip-top">
                                            {t("Enter the number of members you require for this service")}
                                        </Tooltip>
                                    }
                                >
                                    <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                </OverlayTrigger>
                                <NumberInput
                                    value={no_of_member}
                                    onChange={evt => this.handleChange('no_of_member', evt.target.value)}
                                />
                            </Col>
                        </Row>
                    </div>
                    <Row className='mt-3'>
                        <Col md={3} xs={6}>
                            <label className='fw-600'>{t('From Date')}<RequiredStar /></label>
                            <DatePicker
                                selected={from_date && new Date(from_date)}
                                onChange={(date) => this.handleChange('from_date', date)}
                                dateFormat={longDateFormat(dateTimeRules)}
                            />
                        </Col>
                        <Col md={3} xs={6}>
                            <label className='fw-600'>{t('To Date')}<RequiredStar /></label>
                            <DatePicker
                                selected={to_date && new Date(to_date)}
                                onChange={(date) => this.handleChange('to_date', date)}
                                dateFormat={longDateFormat(dateTimeRules)}
                            />
                        </Col>
                    </Row>
                    <Row className='d-flex mt-3'>
                        <Col md={3} xs={6}>
                            <label className='fw-600'>{t('From time')}<RequiredStar /></label>
                            <TimePickerInput
                                value={minutesToTimeInput(from_time)}
                                format={format === "hh:mm" ? "HH:mm" : format}
                                onChange={(value) => this.handleChange('from_time', getMinutesFromTimeInput(value))}
                            />
                        </Col>
                        <Col md={3} xs={6}>
                            <label className='fw-600'>{t('To time')}<RequiredStar /></label>
                            <TimePickerInput
                                value={minutesToTimeInput(to_time)}
                                format={format === "hh:mm" ? "HH:mm" : format}
                                onChange={(value) => this.handleChange('to_time', getMinutesFromTimeInput(value))}
                            />
                        </Col>
                    </Row >
                    <div className='mt-3'>
                        <Textarea
                            label={t('Comment')}
                            value={comment}
                            style={{ width: '100%' }}
                            onChange={(e) => this.handleChange('comment', e.target.value)}
                            placeholder={t('Enter comments here')}
                        />
                    </div>
                    {!disableInput ?
                        <div className='d-flex mt-3'>
                            <Button fill onClick={() => this.handleSave()} disabled={status === 'approved'}>
                                {t('Save')}
                            </Button>
                            <Button dynamic_lg onClick={() => this.handleCancel()} className='ml-10'>
                                {t('Cancel')}
                            </Button>
                        </div>
                        : <></>
                    }
                </>
                :
                <>
                    <div className='table-container-header'>{t('Service Requests Form')}</div>
                    <div className='mt-2'>
                        <Row>
                            <Col md={6} xs={12}>
                                <label className='fw-600'>{t('Project')}</label>
                                <ProjectsWidget
                                    label={`${t('Project')}`}
                                    allLabel={t('All projects')}
                                    value={project_id}
                                    disabled={disableInput}
                                />
                                <label className='fw-600'>{t('Service')}</label>
                                <TasksWidget
                                    className="mt-2 pb-2"
                                    value={task_id}
                                    disabled={disableInput}
                                />
                                <NumberInput
                                    className={'mt-2'}
                                    label={`${t('Total Members Required')}`}
                                    value={no_of_member}
                                    disabled={disableInput}
                                />
                                <Row className='mt-3'>
                                    <Col md={6} xs={12}>
                                        <label className='fw-600'>{t('From Date')}</label>
                                        <DatePicker
                                            selected={from_date && new Date(from_date)}
                                            onChange={(date) => this.handleChange('from_date', date)}
                                            dateFormat={shortDateFormat(from_date, dateTimeRules)}
                                            disabled={disableInput}
                                        />
                                    </Col>
                                    <Col md={6} xs={12}>
                                        <label className='fw-600'>{t('To Date')}</label>
                                        <DatePicker
                                            selected={to_date && new Date(to_date)}
                                            onChange={(date) => this.handleChange('to_date', date)}
                                            dateFormat={shortDateFormat(to_date, dateTimeRules)}
                                            disabled={disableInput}
                                        />
                                    </Col>
                                </Row>
                                <Row className='d-flex mt-3'>
                                    <Col md={6} xs={12}>
                                        <label className='fw-600'>{t('From time')}</label>
                                        <TimePickerInput
                                            value={minutesToTimeInput(from_time)}
                                            format={format === "hh:mm" ? "HH:mm" : format}
                                            disabled={disableInput}
                                        />
                                    </Col>
                                    <Col md={6} xs={12}>
                                        <label className='fw-600'>{t('To time')}</label>
                                        <TimePickerInput
                                            value={minutesToTimeInput(to_time)}
                                            format={format === "hh:mm" ? "HH:mm" : format}
                                            disabled={disableInput}
                                        />
                                    </Col>
                                </Row >
                            </Col>
                            {disableInput &&
                                <Col md={6} xs={12}>
                                    <div>
                                        <label className='fw-600'>{t('Select Members')} ({no_of_member} {t('Member')})</label>
                                        <CustomMultiSelect
                                            options={userOptions}
                                            value={selectedMembers}
                                            onChange={(e) => this.handleShiftAssignMembers(e)}
                                            className={"custom-multi-select-max-height-150"}
                                            overrideStrings={{
                                                selectSomeItems: t('Select Employees'),
                                                allItemsAreSelected: t('All Employees are selected'),
                                                search: t('Search'),
                                                selectAll: t('Select All Employees'),
                                            }}
                                        />
                                    </div>
                                    {status === 'approved' &&
                                        <div className='mt-3 fw-600'>
                                            {t('shifts')} {t('Assigned To')}
                                            {userOptions.map((ele, index) => (
                                                <div className="mt-2 p-1 border-radius-10 col-md-9 ml-10" style={{ backgroundColor: !(index % 2 === 0) ? "#FFFFFF" : "#FAFAFA", border: "1px solid rgba(0, 0, 0, 0.125)" }}>
                                                    <div className='ml-10'>
                                                        {ele.label}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </Col>
                            }
                        </Row>
                    </div>
                    <div className='mt-3'>
                        <Textarea
                            label={t('Comment')}
                            value={comment}
                            style={{ width: '100%' }}
                            disabled={disableInput}
                            placeholder={t('Enter comments here')}
                        />
                    </div>
                    <div className='m-3 d-flex justify-content-end'>
                        {(status === 'active' || status === 'rejected') &&
                            <Button fill style={{ backgroundColor: 'green', border: 0 }} onClick={() => this.handleStatus('approved')}>
                                {t('Approve & Create Shift')}
                            </Button>
                        }
                        {status === 'active' &&
                            <Button fill style={{ backgroundColor: 'red', border: 0, color: 'white' }} className='ml-10' onClick={() => this.handleStatus('rejected')}>
                                {t('Reject')}
                            </Button>
                        }
                        <Button dynamic_lg onClick={() => this.props.handleCloseServiceRequestModal()} className='ml-10'>
                            {t('Cancel')}
                        </Button>
                    </div>
                </>
        )
    }
}

export default inject('userStore', 'authStore', 'serviceRequestStore', 'projectStore')(applicationRouter(withLocalization(ServiceRequestsForm)));