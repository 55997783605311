import config from './config';

export const timeLocale = () => {
    return config.SERVER_IS_NORWAY ? 'nb' : 'en';
};
export const serverTimeMonthDay = () => {
    return !config.SERVER_IS_NORWAY;
};

export const serverTimeFormat = (skipYear = false, withTime = false) => {
    if (skipYear) {
        return config.SERVER_IS_NORWAY ? 'DD.MM' : 'MM/DD';
    }
    if (withTime) {
        return config.SERVER_IS_NORWAY ? 'D MMMM YYYY, HH:mm' : 'D MMMM YYYY, hh:mm a';
    }
    return config.SERVER_IS_NORWAY ? 'DD.MM.YYYY' : 'MM/DD/YYYY';
};

export const serverIsAmPmTimeFormat = () => {
    return !config.SERVER_IS_NORWAY;
};

export const serverValidation = (code) => {
    if (code === 'phone') {
        return /^(\+|\d)( ?\d){6,13}$/;//common for all region;
        //return config.SERVER_IS_NORWAY ? /^((\+47)?\d{8})|((\+48)?\d{9})$/ : /^(\+\d{1,3}[- ]?)?\d{10}$/;
    }
    if (code === 'social') {
        return config.SERVER_IS_NORWAY ? /^(\d{11})$/ : /.*/;
    }
    if (code === 'zip') {
        // return config.SERVER_IS_NORWAY ? /^\d{4}$/ : /^\d{5}$/;
        return /^\d{4,5}$/;
    }
    if (code === 'email'){
        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    }
    if (code === 'tax'){
        return /^\d{9}$/;
    }
    if (code === 'bank_account_number'){
        return /^(\d{11,20})$/
    }
    return /.*/;
};

export const defaultLanguage = () => {
    return config.SERVER_IS_NORWAY ? 'Norsk' : 'English';
};

export const moneySymbol = () => {
    // return config.SERVER_IS_NORWAY ? 'nok' : '$';
    return 'nok'
};

export const hourSymbol = () => {
    return config.SERVER_IS_NORWAY ? 't' : 'h';
};