import { action, observable, makeObservable } from 'mobx';
import moment from 'moment/min/moment-with-locales';
import _ from 'lodash';

//service
import agent from '../agent';

//stores
import userStore from './userStore';
import commonStore from './commonStore';

//utils
import { getMinutesFromTimeInput, minutesToTimeInput } from '../../utils/timeCalc';

const timelogInitial = {
    task_id: null,
    workplace: '',
    from: '08:00',
    to: '16:00',
    break: 0,
    total_hours_normal: '08:00',
    total_hours_overtime: '00:00',
    for_date: new Date(),
    data: {},
    distance: sessionStorage.getItem('last_km_filled') || 0,
    signature: null,
    Attachments: [],
};

class TimelogStore {

    constructor() {
        makeObservable(this, {
            currentList: observable,
            currentEntity: observable,
            originalEntity: observable,
            updatingErrors: observable,
            updating: observable,
            loading: observable,
            requestParams: observable,
            deleteSuccess: observable,
            lastListLoadTime: observable,
            allLocations: observable,
            resetLastListLoadTime: action,
            timelogShift: observable,
            tip_Filter: observable,
            appliedTipFilters: observable,
            filters: observable,
            appliedFilters: observable,
            setFilter: action,
            onFilter: action,
            setTipFilter: action,
            onTipFilter: action,
            massPushNotification: action,
            singlePushNotification: action,
            loadList: action,
            changeEntity: action,
            calcTotalTime: action,
            calcOvertimes: action,
            returnDefaultNew: action,
            load: action,
            saveAndOverrideCurrentEntity: action,
            save: action,
            remove: action,
            setStatus: action,
            getPossibleDraftId: action,
            getCurrentDraftOrSkip: action,
            getCurrentDraftForLocked: action,
            deleteTip: action,
            updateTip: action,
            getTipList: action,
            finishByButton: action,
            reportInjury: action,
            startByButton: action,
            addTip: action,
            setManualDefaults: action,
            switchToManual: action,
            breakByButton: action,
            getAllLocations: action,
            updateLoaderState: action,
            validateTimelogWithIntegration: action
        });
    }

    currentList = [];
    currentEntity = timelogInitial;
    originalEntity = timelogInitial;
    updatingErrors = null;
    updating = false;
    loading = false;
    requestParams = null;
    deleteSuccess = false;
    lastListLoadTime = null;
    allLocations = [];
    timelogShift = null;
    tip_Filter = {
        status: '',
        name: '',
    };
    appliedTipFilters = {
        name: '',
    };
    filters = {
        status: '',
        name: '',
        showAnomalies: false,
    };
    appliedFilters = {
        status: '',
        name: '',
        showAnomalies: false,
    };

    resetLastListLoadTime(value) {
        this.lastListLoadTime = value;
    };

    setFilter(name, value, clearFilter) {
        const filters = Object.assign({}, this.filters);
        filters[name] = value;
        this.filters = filters;
        if(clearFilter){
            this.filters = {}
        }
    }

    onFilter() {
        this.appliedFilters = Object.assign({}, this.filters);
    }

    setTipFilter(name, value) {
        const tip = Object.assign({}, this.tip_Filter);
        tip[name] = value;
        this.tip_Filter = tip;
    }

    onTipFilter() {
        this.appliedTipFilters = Object.assign({}, this.tip_Filter);
    }

    massPushNotification(ids, comment, action) {
        const users = this.currentList.rows.filter(row => ids.indexOf(`${row.id}`) >= 0).map(row => row.user_id);
        const uniqueUsers = [...(new Set(users))];
        return Promise.all(uniqueUsers.map(id => agent.Timelogs.sendMassStatusPushNotification(id, comment, action)));
    }

    singlePushNotification(id, comment, action, syncWithIntegration) {
        return agent.Timelogs.sendSingleStatusPushNotification(id, comment, action, syncWithIntegration);
    }

    updateLoaderState(status) {
        this.loading = status;
    }

    loadList(params) {
        return agent.Timelogs.list(params)
            .then(
                action((list) => {
                    this.requestParams = params;
                    list.time = new Date();
                    this.lastListLoadTime = list.time;
                    this.currentList = list;
                    return list;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    changeEntity(name, value, kind, isAuto) {
        const entity = Object.assign({}, this.currentEntity);
        const nameArray = name.split('.');
        if (nameArray.length === 2) {
            const [firstProp, secondProp] = nameArray;
            entity[firstProp] = entity[firstProp] || {};
            entity[firstProp][secondProp] = value;
        } else if (nameArray.length === 3) {

            const [firstProp, secondProp, thirdProp] = nameArray;
            entity[firstProp] = entity[firstProp] || {};
            entity[firstProp][secondProp] = entity[firstProp][secondProp] || {};
            entity[firstProp][secondProp][thirdProp] = value;
        }
        else {
            entity[name] = value;
        }
        this.currentEntity = entity;
        if (name === 'from' || name === 'to' || name === 'break') {
            this.calcTotalTime();
        }
        if (kind === 'overtime') {
            this.calcOvertimes();
        }
        if (name === 'for_date' && entity.task_id && !isAuto) {
            let tempEntity = {
                id: this.currentEntity.id,
                for_date: moment(this.currentEntity.for_date).format('YYYY-MM-DD'),
                user_id: this.currentEntity.user_id
            }
            return agent.Timelogs.save(tempEntity, false).then(
                action((entity) => {
                    const timelog = this.prepareLoadedEntity(entity.timelog);
                    this.currentEntity = {
                        ...timelogInitial,
                        ...timelog,
                    };
                    this.originalEntity = {
                        ...timelogInitial,
                        ...timelog,
                    };
                    this.calcTotalTime();
                })
            ).catch(
                action((err) => {
                    throw err;
                })
            );
        }
    }

    changeAutoOvertimeProperties(name, currentEntity, config) {
        const overtimeAutomaticMode = config.client?.data?.basicRules?.overtimeAutomaticMode;
        const entity = Object.assign({}, currentEntity);
        if (overtimeAutomaticMode && (name === 'from' || name === 'to')) {
            const overtimeThresoldMinutes = parseInt(config.client.data.basicRules.overtimeThresoldMinutes);
            const calc_period = config.client.data.basicRules.overtimeCalcRules.calc_period;
            const max_hours_in_mins = config.client.data.basicRules.overtimeCalcRules.max_hours * 60;
            const max_minutes = config.client.data.basicRules.overtimeCalcRules.max_minutes;
            const total_overtime_threshold_in_mins = max_hours_in_mins + max_minutes + overtimeThresoldMinutes;
            const overtime_limit_in_mins = max_hours_in_mins + max_minutes;

            const fromMinutes = getMinutesFromTimeInput(entity.from);
            const endMinutes = getMinutesFromTimeInput(entity.to);
            const breakMinutes = entity.break || 0;
            entity.breakMinutes = breakMinutes;
            let totalMinutes = endMinutes - fromMinutes;
            if (endMinutes < fromMinutes) {
                totalMinutes += 24 * 60;
            }
            if (totalMinutes > breakMinutes) {
                totalMinutes -= breakMinutes;
            }
            if (calc_period === 'day') {
                if (total_overtime_threshold_in_mins < totalMinutes) {
                    entity._total_hours_overtime = totalMinutes - (overtime_limit_in_mins);
                    entity._total_hours_normal = overtime_limit_in_mins;
                    this.currentEntity = entity;
                }
                else {
                    delete entity._total_hours_overtime
                    delete entity._total_hours_normal
                    this.currentEntity = entity;
                }
            }
        }
    }

    calcTotalTime() {
        const entity = Object.assign({}, this.currentEntity);
        const fromMinutes = getMinutesFromTimeInput(entity.from);
        const endMinutes = getMinutesFromTimeInput(entity.to);
        const breakMinutes = entity.break || 0; // Math.round(entity.break / 15) * 15;
        entity.breakMinutes = breakMinutes;
        let totalMinutes = endMinutes - fromMinutes;
        if (endMinutes < fromMinutes) {
            // && fromMinutes > (12 * 60)
            // this is probably 12-h shift
            totalMinutes += 24 * 60;
        }
        if (totalMinutes > breakMinutes) {
            totalMinutes -= breakMinutes;
        } else {
        }
        const totalResultPure = minutesToTimeInput(totalMinutes, 'full');
        entity.total_hours_overall = totalResultPure.positive ? totalResultPure.value : '00:00';
        totalMinutes -= getMinutesFromTimeInput(entity.total_hours_overtime);
        const totalResult = minutesToTimeInput(totalMinutes, 'full');
        entity.total_hours_normal = totalResult.positive ? totalResult.value : '00:00';
        this.currentEntity = entity;
    }

    calcOvertimes() {
        const entity = Object.assign({}, this.currentEntity);
        let overtime = 0;
        Object.keys(entity.data.overtimes).forEach((key) => {
            const over = entity.data.overtimes[key];
            overtime += getMinutesFromTimeInput(over.value); // * ((over.multiplier + 100) / 100);
        });
        overtime = minutesToTimeInput(overtime, 'full');
        entity.total_hours_overtime = overtime.positive ? overtime.value : '00:00';
        this.currentEntity = entity;
        this.calcTotalTime();
    }

    returnDefaultNew(params) {
        const t = _.cloneDeep(timelogInitial);
        const clientConfig = commonStore.config.client.data;
        // t.for_date = userStore.currentUser.timelog_last_filled ? moment(new Date(userStore.currentUser.timelog_last_filled)).add(1, 'days').toDate() :
        t.for_date = this.currentEntity.for_date;
        if (moment(t.for_date).isAfter(moment(new Date()))) {
            t.for_date = this.currentEntity.for_date;
        }
        t.user_id = userStore.currentUser.id;
        t.from = clientConfig.basicRules.startTimeRules.start;
        t.to = clientConfig.basicRules.startTimeRules.end;
        t.break = clientConfig.basicRules.breakTimeRules.minutes;
        t.data.address = ''; // userStore.currentUser.address;
        t.data.workplace = '';
        this.currentEntity = t;
        this.loading = false;
    }

    prepareLoadedEntity(loadedData) {
        if (!loadedData.data) {
            loadedData.data = {};
        }
        loadedData.distance = loadedData.data.distance || loadedData.distance;
        loadedData.for_date = new Date(loadedData.for_date);
        loadedData.from = minutesToTimeInput(loadedData.from, false);
        loadedData.to = minutesToTimeInput(loadedData.to, false);
        loadedData.total_hours_normal = minutesToTimeInput(loadedData.total_hours_normal, false);
        loadedData.total_hours_overtime = minutesToTimeInput(loadedData.total_hours_overtime, false);
        const breakMinutes = loadedData.break || 0; // Math.round(loadedData.break / 15) * 15;
        loadedData.break = breakMinutes;
        return loadedData;
    }

    load(id) {
        this.loading = true;
        return agent.Timelogs.load(id)
            .then(
                action((response) => {
                    const timelog = this.prepareLoadedEntity(response.timelog);
                    this.timelogShift = response.shift;
                    this.currentEntity = {
                        ...timelogInitial,
                        ...timelog,
                    };
                    this.originalEntity = {
                        ...timelogInitial,
                        ...timelog,
                    };
                    this.calcTotalTime();
                    this.loading = false;
                    return response;
                })
            )
            .catch(
                action((err) => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    saveAndOverrideCurrentEntity(values, isAdd, intermediate) {
        this.currentEntity = JSON.parse(JSON.stringify(values));
        return this.save(values, isAdd, intermediate);
    }

    save(values, isAdd, intermediate) {
        class ValidationError extends Error {
            constructor(message) {
                super(message); // (1)
                this.name = 'ValidationError'; // (2)
            }
        }

        // localStorage.setItem('last_km_filled', this.currentEntity.distance);
        sessionStorage.setItem('last_km_filled', this.currentEntity.distance);
        // this.currentEntity.data.distance = this.currentEntity.distance;

        this.currentEntity.intermediateSave = !!intermediate;

        if (!intermediate && !this.currentEntity.description) {
            this.updating = false;
            this.updatingErrors = { message: 'Comment is required' };
            throw new ValidationError('Comment is required');
            // return Promise.reject({message: 'Comment is required'});
        }
        if (!intermediate && !this.currentEntity.task_id) {
            this.updating = false;
            this.updatingErrors = { message: 'Task is required' };
            throw new ValidationError('Task is required');
            // return Promise.reject({message: 'Comment is required'});
        }

        if (intermediate) {
            this.currentEntity.for_date = moment(this.currentEntity.for_date).format('YYYY-MM-DD')
            return agent.Timelogs.save(this.currentEntity, false).then(
                action((tl) => {
                    this.changeEntity('distance_type', tl.timelog.distance_type)
                    return tl;
                })
            );
        }

        this.updating = true;
        const user = userStore.currentUser;

        const entity = _.cloneDeep(this.currentEntity);
        if (entity.status === 'draft') entity.status = 'active';
        entity.for_date = moment(entity.for_date).format('YYYY-MM-DD');
        entity.autoAdjust = values?.autoAdjust
        if (!entity.id) isAdd = true;
        return agent.Timelogs.save(entity, isAdd)
            .then(
                action((tl) => {
                    // this.currentEntity = tl;
                    this.changeEntity('distance_type', tl.timelog.distance_type)
                    if (tl.timelog.user_id === user.id) {
                        userStore.pullUser();
                    }
                    return tl;
                })
            )
            .catch((err) => {
                // this.updating = false;
                // this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                if (err.response?.body)
                    return err.response.body
                throw err;
            });
    }

    async remove(id) {
        await agent.Timelogs.remove(id);
        this.deleteSuccess = true;
        return 1;
    }

    async setStatus(id, entityName, status, _data = {}, syncWithIntegration, shiftCreationParams) {
        const data = Object.assign({}, _data);
        data.status = status;
        data.syncWithIntegration = syncWithIntegration
        data.shiftCreationParams = shiftCreationParams;
        return agent.Timelogs.setStatus(entityName, id, data);
    }

    async getPossibleDraftId(autostart) {
        const r = await agent.Timelogs.getDraftId(autostart);
        if (autostart) {
            return r.timelog;
        }
        return r.timelog.id;
    }

    async getCurrentDraftOrSkip(forceProject = '', user = '', onlyValid = false) {
        const r = await agent.Timelogs.getCurrentDraftOrSkip(forceProject, user, onlyValid);
        return r;
    }

    async getCurrentDraftForLocked(forceProject = '', user = '') {
        const r = await agent.Timelogs.getCurrentDraftForLocked(forceProject, user);
        return r;
    }
    async deleteTip(id) {
        await agent.Timelogs.deleteTip(id).then(
            action((res) => {
                return res;
            })
        ).catch(
                action((err) => {
                    throw err;
                })
            );
    }

    async updateTip(id, data) {
        await agent.Timelogs.updateTip(id, data).then(
            action((res) => {
                return res;
            })
        ).catch(
            action((err) => {
                throw err;
            })
        );
    }

    getTipList(params) {
        this.loading = true;
        return agent.Timelogs.getTipList(params).then(
            action((res) => {
                this.loading = false;
                res.time = new Date();
                this.lastListLoadTime = res.time;
                return res;
            })
        )
            .catch(
                action((err) => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    async finishByButton(timelog, data) {
        try {
            const response = await agent.Timelogs.finishByButton(timelog.id, data);
            this.load(timelog.id);
            return response.timelog;
        }
        catch(err) {
            if (err.response?.body)
                return err.response.body
        }
    }

    async reportInjury(timelog, data) {
        const response = await agent.Timelogs.reportInjury(timelog.id, data);
        this.load(timelog.id);
        return response.timelog;
    }

    async startByButton(id, data) {
        try {
            await agent.Timelogs.startByButton(id, data);
            this.load(id);
        }
        catch(e){
            commonStore.addNotification(e, null, 'error');
        }
        finally{
            this.load(id);
        }
    }
    async addTip(data) {
        await agent.Timelogs.addTip(data).then(
            action((res) => {
                return res;
            })
        ).catch(
                action((err) => {
                    throw err;
                })
            );
    }
    setManualDefaults() {
        this.currentEntity.tracker_status = 'manual';
        this.currentEntity.status_note = ''; //'SUSPECT'
        const clientConfig = commonStore.config.client.data;

        this.currentEntity.from = clientConfig.basicRules.startTimeRules.start;
        this.currentEntity.to = clientConfig.basicRules.startTimeRules.end;
        this.currentEntity.break = clientConfig.basicRules.breakTimeRules.minutes;
        this.calcTotalTime();
    }

    switchToManual() {
        if (this.currentEntity.gps_data) {
            this.currentEntity.gps_data.start = { manual: true };
            this.currentEntity.gps_data.end = { manual: true };
        }
        this.currentEntity.tracker_status = 'manual';
    }

    async breakByButton(timelog, action, reload) {
        const response = await agent.Timelogs.breakByButton(timelog, action);
        if (reload) {
            this.load(timelog);
        }
        return response.timelog;
    }

    async getAllLocations(params) {
        this.loading = true;
        const response = await agent.Timelogs.getAllLocations(params);
        this.allLocations = response;
        this.loading = false;
        return response;
    }

    validateTimelogWithIntegration(timelog) {
        return agent.Timelogs.validateTimelogWithIntegration(timelog).then(
            action((res) => {
                return res.message;
            })
        ).catch(
            action((err) => {
                throw err;
            })
        );
    }

    handleChangeExtraPayments = (extraPayment, value, isReturnNeeded) => {
        const { code } = extraPayment;
        const data = this.currentEntity.data ? Object.assign({}, this.currentEntity.data) : {};
        data.extraPayments = data.extraPayments || {};
        data.extraPayments[code] = {
            ...extraPayment,
            value: getMinutesFromTimeInput(value),
        };
        let extraPayments = JSON.parse(JSON.stringify(data.extraPayments));
        let newExtraPayment = extraPayments;

        for (let key in Object.keys(extraPayments)) {
            const consideredData = extraPayments[key];
            if (!consideredData?.value) {
                delete newExtraPayment[key];
            }
        }
        data.extraPayments = newExtraPayment;
        this.changeEntity('data', data);
        if (isReturnNeeded) {
            return data;
        }
    };
}

const _TimelogStore = new TimelogStore();
export default _TimelogStore;