import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { cloneDeep } from 'lodash';

//components
import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '~/hoc/withLocalization';
import GenericForm from '~/components/GenericForm/GenericForm';
import AcceptFormBlock from '../Timelogs/AcceptFormBlock';
import VacationsTotals from './VacationsTotals';

//elements
import LoadingSpinner from '~/elements/LoadingSpinner';

//utils
import customerFeature from '~/utils/customerFeature';
import getSchema from '~/library/schemas/vacation';

const defaultUiSchema = {
    'ui:field': 'layout',
    'ui:layout:hideframe': true,
    'ui:layout': [
        {
            vacation_type: { xs: 6, sm: 4 },
            project_id: { xs: { span: 6, order: 2 }, sm: 4 },
        },
        {
            from: { xs: 4, className: "mt-3" },
            to: { xs: 4, className: "mt-3" },
            total_days: { xs: 4, className: "mt-3" },
        },
        {
            comments: { md: 12, className: "mt-2" },
        },
        {
            attachments: { md: 12, className: "mt-2" },
        },
    ],
    vacation_type: {
        'ui:widget': 'VacationStatuses',
    },
    attachments: {},
    user_id: {
        'ui:widget': 'MembersWidget',
    },
    project_id: {
        'ui:widget': 'ProjectsWidget',
        'ui:user_id': null
    }
};

class VacationsForm extends Component {
    constructor(props) {
        super(props)
        this.extraPaymentRules = props.commonStore.config.client.data.extraPayments;
        this.state = {
            schema: getSchema(),
            uiSchema: defaultUiSchema,
            formTimeStamp: Date.now(),
        };
    }

    getListUrl() {
        return '/admin/vacations';
    }

    async handleChangeStatus() {
        this.loadData();
    }

    async loadData() {
        const { vacationStore, location } = this.props;
        this.setState({
            schema: getSchema({
                config: this.props.commonStore.config,
            }),
        });
        let getId = (this.props.router.location.state && this.props.router.location.state.vacation) || null;
        if (!getId) {
            this.setState({ uiSchema: this.prepareSchema(defaultUiSchema, null) });
            return vacationStore.returnDefaultNew({ location, user_id: this.props.userStore.currentUser.id });
        }
        const vacation = await vacationStore.load(getId, !getId);
        this.setState({ uiSchema: this.prepareSchema(defaultUiSchema, vacation.vacation) });
    }

    prepareSchema(_schema, currentEntity) {
        const schema = cloneDeep(_schema);
        const { userStore } = this.props;
        const { currentUser } = userStore;

        schema.attachments = {
            'ui:widget': 'AttachmentsWidget',
            'ui:imageContext': {
                model: 'Vacation',
                fileType: 'docs',
                id: currentEntity && currentEntity.id !== 'add' ? currentEntity.id : 0,
                existingAttachment: currentEntity && currentEntity.Attachments ? currentEntity.Attachments : [],
            },
        };
        const showUser = (currentUser.user_type === 'admin' || currentUser.user_type === 'pm')
            && customerFeature('pm_allow_editing_all')
            && !customerFeature('disallow_absences_vacations_not_self');
        if (showUser) {
            schema['ui:layout'][0].user_id = { xs: 6, sm: 4, md: 4 };
        }
        if (currentUser.user_type === 'pm') {
            schema.project_id = {
                'ui:widget': 'ProjectsWidget',
                'ui:user_id': currentUser.id
            }
        }
        if (currentUser.user_type === 'member') {
            if (schema.user_id) schema.user_id.classNames = 'hidden';
        }
        return schema;
    }

    componentWillMount() {
        this.props.vacationStore.loading = true;
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(previousProps) {
        const { location } = this.props;
        if (location !== previousProps.location) {
            this.loadData();
        }
    }

    onSave(values) {
        const { vacationStore, t, commonStore } = this.props;
        const { userStore } = this.props;
        const { currentUser } = userStore;
        const { biztype } = commonStore.config;

        let getId = (this.props.router.location.state && this.props.router.location.state.vacation) || null;
        if (currentUser.user_type === 'member' && !getId) {
            values.user_id = currentUser.id;
        }
        const { currentEntity } = vacationStore;

        let allow = (currentUser.user_type === 'admin' || currentUser.user_type === 'pm')
            && customerFeature('pm_allow_editing_all')
            && !customerFeature('disallow_absences_vacations_not_self');

        if (currentEntity && currentEntity.vacation && currentEntity.vacation.status === 'approved') allow = false;

        if (currentUser.id !== values.user_id && !allow) {
            commonStore.addNotification(t('Can not save on behalf of others'), null, 'error');
            return false;
        }

        if (!values.total_days) {
            commonStore.addNotification(t("Total days should be atleat 1 day, please check if you have selected holidays"), null, 'warning');
            return false;
        }

        if (!values.project_id && biztype.data && biztype.data.allow_multiple_branches) {
            return commonStore.addNotification(t("Please select Project ( Branch ) and then try again"), null, 'warning');
        }

        return vacationStore.save(values, !getId).then(result => {
            if (!result.vacation || !result.vacation.id) {
                commonStore.addNotification(t(result.message || 'Error'), null, 'error');
                return false;
            }
            commonStore.addNotification(t('Saved'), null, 'success');
            this.getBack();
            return true;
        });
    }

    updateSchema = (all, oldData) => {
        const { formData, uiSchema } = all;
        if (this.props.userStore.currentUser.id !== formData.user_id && oldData.user_id !== formData.user_id) {
            const _uiSchema = cloneDeep(uiSchema);
            _uiSchema.project_id = {
                'ui:widget': 'ProjectsWidget',
                'ui:user_id': formData.user_id
            }
            this.setState({ uiSchema: _uiSchema, formTimeStamp: Date.now() });
        }
    }

    getBack = () => {
        const { page } = this.props.router.location.state || {};
        this.props.router.navigate('/admin/vacations', { state: { page: page, isEdit: true } });
    }

    render() {
        const { vacationStore, add } = this.props;
        const { loading, currentEntity } = vacationStore;
        const { schema, uiSchema, formTimeStamp } = this.state;
        const { user_type, id: userId } = this.props.userStore.currentUser;

        const showStatus = (user_type === 'pm' || user_type === 'admin') && customerFeature('allow_accepting') && !add;
        let allow = (user_type === 'admin' || user_type === 'pm')
            && customerFeature('pm_allow_editing_all')
            && !customerFeature('disallow_absences_vacations_not_self');
        allow = !add && currentEntity && currentEntity.vacation && currentEntity.vacation.user_id !== userId && !allow;
        if (currentEntity && currentEntity.vacation && currentEntity.vacation.status === 'approved') allow = true;

        if (loading) return <LoadingSpinner />;

        return (
            <div className="primary-page">
                <VacationsTotals user={currentEntity.vacation.user_id} currentvacation={vacationStore.currentEntity} />
                <GenericForm
                    key={formTimeStamp}
                    entity={currentEntity.vacation}
                    uiSchema={uiSchema}
                    schema={schema}
                    translationScope="forms.vacation"
                    onChange={async data => vacationStore.recalc(data, this.extraPaymentRules)}
                    onSave={values => this.onSave(values)}
                    listUrl={this.getListUrl()}
                    isAdding={!!this?.props?.router?.location?.state?.vacation ? true : false}
                    disallowSave={allow}
                    goBack={() => this.getBack()}
                    updateSchema={this.updateSchema}
                />
                {showStatus && !!this?.props?.router?.location?.state?.vacation && (
                    <AcceptFormBlock
                        params={{ id: this.props.router?.location?.state?.vacation, status: currentEntity.vacation.status, data: vacationStore.currentEntity, entity: 'vacation' }}
                        afterSave={() => this.handleChangeStatus()}
                    />
                )}
            </div>
        );
    }
}

export default inject('vacationStore', 'commonStore', 'userStore')(applicationRouter(withLocalization(observer(VacationsForm))));