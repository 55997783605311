import React, { useEffect, useState } from 'react'
import {
    useTable,
    usePagination,
    useSortBy,
    useFlexLayout,
    useRowSelect
} from 'react-table';
import { v4 as uuid } from 'uuid';


//components
import TablePagination from './TablePagination';
import withLocalization from '../../hoc/withLocalization';

//elements
import Button from '../CustomButton/CustomButton.jsx';
import LoadingSpinner from '~/elements/LoadingSpinner';

function GenericList(props) {
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [pageIndex] = useState(0);
    const [pageSize] = useState(props.pageSize ? props.pageSize : 10);
    const [selected, setSelected] = useState({});
    const [sortBy, setSortBy] = useState([]);
    const [selectAll, setSelectAll] = useState('');
    const [filters] = useState(props.filters);
    const [pages, setPages] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [lastListLoadTime, setLastListLoadTime] = useState(null);
    const [loading, setLoading] = useState(false);
    const [expandedRows, setExpandedRows] = useState({});
    const [isAllRowExpanded, setIsAllRowExpanded] = useState(0);

    useEffect(() => {
        translateColumns();
    }, [props?.i18n?.language, selected])


    //only calls for any change in filter//
    useEffect(() => {
        fetchData({ pageIndex, pageSize, sortBy, filters })
    }, [props.filters]);

    if (props.lastListLoadTime && lastListLoadTime && props.lastListLoadTime !== lastListLoadTime && !loading) {
        fetchData({ pageIndex, pageSize, sortBy, filters });
    }

    function translateColumns() {
        const { columns, t, checkboxField } = props;
        let _columns = [];
        columns.forEach((c) => {
            if (c && !c.hidden) _columns.push(c);
        });
        _columns = _columns.map((column) => {
            const { Header, ...cols } = column;
            return { Header: t(Header), ...cols };
        });

        if (props.allowCheckboxes) _columns.unshift(checkboxCol(checkboxField));
        setColumns(_columns)
        setPageSize(pageSize);
    }

    const checkboxCol = (checkboxField = "id") => {
        return {
            id: 'checkbox',
            accessor: '',
            Cell: (data) => {
                return (
                    <div className='checkbox checkbox__table_list_container'>
                        <input
                            type="checkbox"
                            checked={selected[data?.row?.original?.[`${checkboxField}`]] === true}
                            onChange={() => toggleRow(data?.row?.original?.[`${checkboxField}`])}
                        />
                        <label className={`checkbox__table_list ${props.cutomCheckBoxClass ? props.cutomCheckBoxClass : ""}`}
                            onClick={() => toggleRow(data?.row?.original?.[`${checkboxField}`])}></label>
                    </div>
                );
            },
            Header: (x) => {
                return (
                    <div className='form-checkbox'>
                        <input
                            type="checkbox"
                            className={"checkbox"}
                            checked={selectAll === 1}
                            ref={(input) => {
                                if (input) {
                                    input.indeterminate = selectAll === 2;
                                }
                            }}
                            onChange={() => toggleSelectAll()}
                        />
                    </div>
                );
            },
            sortable: false,
            width: 30,
        };
    }

    const toggleRow = (id) => {
        const newSelected = Object.assign({}, selected);
        newSelected[id] = !selected[id]
        setSelected(newSelected)
        setSelectAll(2)
        if (props.onSelectionChanged) props.onSelectionChanged(newSelected);
    }

    const toggleSelectAll = () => {
        const newSelected = {};
        if (selectAll === 0) {
            data.forEach((x) => {
                newSelected[x.id] = true;
            });
        }
        setSelected(newSelected)
        setSelectAll(selectAll === 0 ? 1 : 0)
        if (props.onSelectionChanged) props.onSelectionChanged(newSelected);
    }

    function fetchData(state) {
        setLoading(true);
        let _currentPage = state.pageIndex;
        if (props.isPageAddedOrEdited !== undefined && props.isPageAddedOrEdited === false) {
            if (props.handlePageChange)
                props.handlePageChange(state.pageIndex, false);
        }

        if (props.isPageAddedOrEdited !== undefined && props.isPageAddedOrEdited === true) {
            _currentPage = props.page;
            if (props.handlePageChange)
                props.handlePageChange(_currentPage, false);
        }

        let { filters } = props;
        if (state.forceFilters) filters = state.forceFilters;
        // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
        // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
        // this.setState({ loading: true });
        // Request the data however you want.  Here, we'll use our mocked service we created earlier
        props.requestData({
            pagesize: state.pageSize,
            page: _currentPage,
            sort: state.sortBy ? state.sortBy : [],
            // React Table default filter
            filters,
        })
            .then((res) => {
                // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
                setData(res.rows);
                setPages(res.pages);
                setCurrentPage(_currentPage);
                setSortBy(state.sortBy);
                setLastListLoadTime(res.time);
                if (res.selected) {
                    const prevSelected = {};
                    res.selected.split(',').forEach(ele => prevSelected[ele] = true);
                    setSelected(prevSelected)
                }
                else {
                    setSelected({});
                    setSelectAll('');
                }
            }).
            catch(err=>{
                console.error({err})
            }).finally(()=>{
                setLoading(false);
            });
    }

    function handlePagination(genericPage) {
        let filters = props.filters
        if (genericPage) {
            fetchData({
                pageSize: pageSize,
                pageIndex: (genericPage - 1),
                sortBy,
                filters,
            })
        }
    }

    function sortList(sort) {
        fetchData({ pageIndex, pageSize, sortBy: sort, filters })
    }

    function renderSortedButtons(column) {
        if (column.isSorted) {
            return (
                <img src={"/images/blueArrow.svg"} alt="arrow"
                    className={`${column.isSortedDesc ? "transFrom-180" : ""}`}
                    onClick={() => sortList(column.isSortedDesc ? [] : [{ "id": column.id, "desc": true }])}
                />
            )
        } else return (
            <img src={"/images/grayArrow.svg"} alt="arrow"
                onClick={() => sortList([{ "id": column.id, "desc": false }])}
            />
        )
    }

    function handleRowExpansion(id) {
        const _expandedRows = { ...expandedRows };
        _expandedRows[id] = !_expandedRows[id];
        if (Object.values(_expandedRows).find(ele => ele === true))
            setIsAllRowExpanded(1)
        else {
            setIsAllRowExpanded(0)
            props.handleExpandAllSubRows(false);
        }
        setExpandedRows(_expandedRows);
    }

    function handleAllRowExpansion(page) {
        if (!isAllRowExpanded) {
            const _expandedRows = expandedRows;
            page.forEach(ele => {
                if (props.hasSubRows(ele.original)) _expandedRows[ele.original.id] = true;
            });
            setExpandedRows(_expandedRows)
            setIsAllRowExpanded(2);
            props.handleExpandAllSubRows(true)
        }
        else {
            const _expandedRows = expandedRows;
            for (let row in _expandedRows) {
                _expandedRows[row] = false;
            }
            setExpandedRows(_expandedRows);
            setIsAllRowExpanded(0);
            props.handleExpandAllSubRows(false)
        }
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        setPageSize
    } = useTable(
        { columns, data, manualSortBy: true },
        useSortBy,
        usePagination,
        useFlexLayout,
        useRowSelect);
    return (
        <div className='row'>
            {/* <List header starts here> */}
            <div className='mt-0'>
                <div className='row align-items-center'>
                    <div className='col-12 col-sm-12 col-md-2'>
                        <span className='table-container-header'>{props.tableHeader ? props.tableHeader : ""}</span>
                    </div>
                    {!props.splitHeader &&
                        <div className='col-12 col-md-10'>
                            <div className='row genericlist_header_xxl_auto justify-content-lg-end'>
                                <div className={`col-12`}>
                                    <div className='table-list__header'>
                                        {props.showSubRows &&
                                            <Button wd onClick={() => handleAllRowExpansion(page)}
                                                disabled={!page.find(ele => props.hasSubRows(ele.original) === true)}
                                                title={props.t(
                                                    page.find(ele => props.hasSubRows(ele.original) === true) ?
                                                        "Apply filter" : "No data found")}
                                            >
                                                <i className="fa fa-exclamation me-1" /> {props.t(!isAllRowExpanded ? 'Show Anomalies' : 'Hide Anomalies')}
                                            </Button>
                                        }
                                        {props.children}
                                        {props.showStatus &&
                                            <Button onClick={() => props.onChangeStatus(selected)} disabled={props.loading}>
                                                {props.t(props.checkBoxButtonName ? props.checkBoxButtonName : 'Change status')}
                                            </Button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>

            {props.splitHeader && <div className='mt-1'>{props.children}</div>}
            {/* <List header ends> */}

            {/* <filter starts here> */}
            {props.header &&
                <div className='mt-1'>
                    {props.header}
                </div>
            }
            {/* <filter ends> */}


            {/* <table starts here> */}
            <div className="table-responsive">
                <table {...getTableProps()} className='table'>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr key={uuid()} {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th key={uuid()} {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className={`${column.textAlign ? `text-${column.textAlign}` : ""} ${column.padddingLeft5 ? "__titile_padding_left" : ""} __flex_header `}
                                        title={column.Header && ['string','number'].includes(typeof(column.Header)) ? column.Header : ""}
                                    >
                                        <span>{column.render('Header')}</span>
                                        {column.isSortable && renderSortedButtons(column)}
                                        {/* <span {...column.getResizerProps()} className={"resizer"} >|</span> */}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {loading ?
                            <tr>
                                <td>
                                    <LoadingSpinner />
                                </td>
                            </tr>
                            :
                            <>
                                {page.length ? page.map(row => {
                                    prepareRow(row)
                                    return (
                                        <>
                                            <tr key={uuid()} {...row.getRowProps()} className='tbody-row'>
                                                {row.cells.map(cell => {
                                                    return (
                                                        <td
                                                            key={uuid()}
                                                            className={cell?.column?.textAlign ? `text-${cell.column.textAlign}` : ""}
                                                            {...cell.getCellProps()}
                                                            title={cell?.value?.props
                                                                && typeof (cell?.value?.props?.children) === 'string'
                                                                ? cell.value.props.children : ""}
                                                            onClick={() => {
                                                                if (cell?.column.id === props.expandableRowHandler && props.hasSubRows(row.original)) handleRowExpansion(row.original.id)
                                                            }}
                                                        >
                                                            {cell.render('Cell')}
                                                        </td>
                                                    )
                                                })}
                                            </tr>
                                            {props.showSubRows && props.hasSubRows(row.original) && (expandedRows[row.original.id] || isAllRowExpanded === 2) &&
                                                <>{props.expandadedRowContent(row.original)} </>
                                            }
                                        </>
                                    )
                                })
                                    :
                                    <tr>
                                        <td className='noData d-flex justify-content-start justify-content-md-center mt-10 w-100'>
                                            {props.t("No data found")}
                                        </td>
                                    </tr>
                                }
                            </>
                        }
                    </tbody>
                </table>
            </div>
            {/* <table ends> */}

            {/* <pagination starts here> */}
            {pages > 1 &&
                <div className="mt-4">
                    <TablePagination
                        pages={pages}
                        requestData={props.requestData}
                        filters={props.filters}
                        handlePagination={handlePagination}
                        page={currentPage}
                    />
                </div>
            }
            {/* <pagination ends> */}
        </div>
    )
}

export default withLocalization(GenericList)