import React, { Component } from 'react';
import applicationRouter from '~/hoc/applicationRouter'
import { inject, observer } from 'mobx-react';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import withLocalization from '~/hoc/withLocalization';
import { NumberInput } from '../../../../elements/Input';
import SwitchWidget from '../SwitchWidget';

class ParentalLeaves extends Component {
    constructor(props) {
        super(props);
        this.state = {
            parentalLeaves: {
                absencePeriodForTwoKids: 20,
                absencePeriodForMoreThanTwoKids: 30,
                allowSingleParentCare: false,
            },
        }
        this.handleTextChange = this.handleTextChange.bind(this)
        this.loadData = this.loadData.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const { currentEntity } = this.props.clientStore;
        const { parentalLeaves } = this.state;
        if (currentEntity.client.data && currentEntity.client.data.basicRules) {
            const basicRules = Object.assign({}, currentEntity.client.data.basicRules);
            parentalLeaves.absencePeriodForTwoKids = basicRules.absencePeriodForTwoKids ? basicRules.absencePeriodForTwoKids : 20;
            parentalLeaves.absencePeriodForMoreThanTwoKids = basicRules.absencePeriodForMoreThanTwoKids ? basicRules.absencePeriodForMoreThanTwoKids : 30;
            parentalLeaves.allowSingleParentCare = basicRules.allowSingleParentCare ? basicRules.allowSingleParentCare : false;
            this.setState({ parentalLeaves });
        }
    }

    handleTextChange = (name, value) => {
        const { parentalLeaves } = this.state;
        parentalLeaves[name] = value;
        this.props.handleUserDataChange(name, value);
        this.setState({ parentalLeaves });
    }

    handleSwitch = (name, evt) => {
        const { parentalLeaves } = this.state;
        parentalLeaves[name] = evt;
        this.props.handleUserDataChange(name, evt);
        this.setState({ parentalLeaves })
    }


    render() {
        const { parentalLeaves } = this.state;
        const { t } = this.props;
        return (
            <div>
                <Row>
                    <Col sm={6}>
                        <NumberInput
                            label={`${t('Absence care days for single or two children')}`}
                            value={parentalLeaves.absencePeriodForTwoKids}
                            onChange={evt => {
                                this.handleTextChange('absencePeriodForTwoKids', parseInt(evt.target.value, 10));
                            }}
                        />
                    </Col>
                    <Col sm={6}>
                        <NumberInput
                            label={`${t('Absence care days for more than two children')}`}
                            value={parentalLeaves.absencePeriodForMoreThanTwoKids}
                            onChange={evt => {
                                this.handleTextChange('absencePeriodForMoreThanTwoKids', parseInt(evt.target.value, 10));
                            }}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: '2rem' }}>
                    <Col sm={1}>
                        <SwitchWidget
                            className='mt-1'
                            value={parentalLeaves.allowSingleParentCare}
                            onChange={checked => this.handleSwitch('allowSingleParentCare', checked)} />
                    </Col>
                    <Col sm={10}>
                        <label className="control-label me-1">{t('Allow extra care days for single parent')}</label>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t(`This function allows users who are single parents to apply for extra parental leave days. If a child has a chronic illness, the parent (user) can apply for additional parental leave through the relevant welfare service or agency. However, the availability and rules for such leave may vary depending on the country's regulations and policies`)}.
                                </Tooltip>
                            }>
                            <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                        </OverlayTrigger>
                    </Col>
                </Row>
            </div>
        );
    }
}
export default inject('clientStore', 'commonStore', 'userStore')(applicationRouter(withLocalization(observer(ParentalLeaves))));
